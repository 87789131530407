var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"id":"core"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"monthPicker","prepend-icon":"mdi-calendar","placeholder":"time report month","readonly":"","rules":[_vm.rules.required]},on:{"change":function($event){return _vm.clearAllowedDates()}},model:{value:(_vm.editedItem.month),callback:function ($$v) {_vm.$set(_vm.editedItem, "month", $$v)},expression:"editedItem.month"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.monthPicker),callback:function ($$v) {_vm.monthPicker=$$v},expression:"monthPicker"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.monthPicker = false}},model:{value:(_vm.editedItem.month),callback:function ($$v) {_vm.$set(_vm.editedItem, "month", $$v)},expression:"editedItem.month"}})],1)],1),_c('v-spacer',{attrs:{"cols":"12","lg":"2"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"datePicker","prepend-icon":"mdi-calendar","placeholder":"time report month","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"show-current":"","show-week":"","no-title":"","allowed-dates":_vm.allowedDates,"first-day-of-week":"1","picker-date":_vm.editedItem.month},on:{"input":function($event){_vm.datePicker = false},"update:picker-date":function($event){return _vm.pickerUpdate($event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"5","sm":"5"}},[_c('v-text-field',{attrs:{"id":"hours","single-line":"","label":"Hours","type":"number","rules":[_vm.rules.required],"hint":"Hours of caring for child","persistent-hint":""},model:{value:(_vm.editVabHours),callback:function ($$v) {_vm.editVabHours=$$v},expression:"editVabHours"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2","sm":"2"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"2","icon":"","large":""},on:{"click":_vm.addVABItem}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-row',[_c('v-data-table',{attrs:{"headers":_vm.vabHeaders,"items":_vm.editedItem.vabItems},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteVABItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveMonth}},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }