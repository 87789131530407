<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row id="core">
          <v-col cols="12" sm="12" lg="5">
            <v-menu
              v-model="monthPicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="monthPicker"
                  v-model="editedItem.month"
                  prepend-icon="mdi-calendar"
                  placeholder="time report month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                v-model="editedItem.month"
                type="month"
                @input="monthPicker = false"
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-show="editedItem.type === 'Normal'">
          <v-col cols="12" sm="12" lg="5">
            <v-select
              id="projectSelect"
              :items="projects"
              item-text="data.name"
              v-model="editedItem.project"
              name="project"
              label="Project"
              hint="Project to report on"
              persistent-hint
              return-object
              :rules="[rules.required]"
              required
            />
          </v-col>
          <v-spacer lg="2" />
          <v-col cols="12" sm="12" lg="5">
            <v-text-field
              id="hours"
              v-model="editedItem.hours"
              single-line
              label="Hours"
              type="number"
              :rules="[rules.required]"
              required
              hint="Total amount of hours by month"
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="cancel" text @click="close"> Close </v-btn>
      <v-btn color="primary" text @click="saveMonth"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "./helpers";

export default {
  name: "MonthlyFormCard",
  components: {},
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    monthPicker: false,
    selected_type: "",
    month: new Date().toISOString().substr(0, 7),
  }),
  computed: {
    ...mapGetters({
      dialogMonth: "dialogMonth",
      editedItem: "editedTimeReport",
      editedIndex: "editedIndex",
      projects: "users/projects",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Time Report" : "Edit Time Report";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      //TODO This should be replace with a model, form a lib
      let tr = helpers.createTimeReport();
      // Set month to current month
      tr.month = new Date().toISOString().substr(0, 7);
      this.$store.dispatch("SET_EDITED_TIME_REPORT", tr);
      this.$store.dispatch("SET_EDITED_INDEX", -1);
      this.$store.dispatch("SET_DIALOG_MONTH", false);
    },
    saveMonth() {
      //TODO: Should do a check if the needed field are set, if not raise a alert
      this.editedItem.type = "Normal";
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("timeReports/updateUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("timeReports/addUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.close();
    },
  },
};
</script>

<style>
</style>