<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row id="core">
          <v-col cols="12" sm="12" lg="5">
            <v-menu
              v-model="monthPicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="monthPicker"
                  v-model="editedItem.month"
                  prepend-icon="mdi-calendar"
                  placeholder="time report month"
                  @change="clearAllowedDates()"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                v-model="editedItem.month"
                type="month"
                @input="monthPicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer cols="12" lg="2" />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-body-1">
                Select vacation dates
              </v-card-title>
              <v-card-text>
                <v-date-picker
                  v-model="vDates"
                  show-week
                  multiple
                  show-current
                  full-width
                  no-title
                  :allowed-dates="allowedDates"
                  first-day-of-week="1"
                  @update:picker-date="pickerUpdate($event)"
                  :picker-date="editedItem.month"
                ></v-date-picker>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="cancel" text @click="close"> Close </v-btn>
      <v-btn color="primary" text @click="saveMonth"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "@/components/timeReport/helpers.js";

export default {
  name: "MonthlyVacationCard",
  components: {},
  data: () => ({
    month: new Date().toISOString().substr(0, 7),
    _availableDates: [],
    monthPicker: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  computed: {
    ...mapGetters({
      editedItem: "editedTimeReport",
      editedIndex: "editedIndex",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "New Vacation Report"
        : "Edit Vacation Report";
    },
    vDates: {
      get() {
        return this.editedItem.vacationDates;
      },
      set(value) {
        this.editedItem.vacationDates = value.sort();
        this.editedItem.hours = value.length * 8;
      },
    },
    availableDates: {
      get() {
        return helpers.fetchAvalibleDates(this.editedItem.month);
      },
      set(value) {
        this._availableDates = value;
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      //TODO This should be replace with a model, form a lib
      let tr = helpers.createTimeReport();
      // set month to current month
      tr.month = new Date().toISOString().substr(0, 7);
      this.$store.dispatch("SET_EDITED_TIME_REPORT", tr);
      this.$store.dispatch("SET_EDITED_INDEX", -1);
      this.$store.dispatch("SET_DIALOG_VACATION", false);
    },
    saveMonth() {
      //TODO: Should do a check if the needed field are set, if not raise a alert
      this.editedItem.type = "Vacation";
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("timeReports/updateUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("timeReports/addUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.close();
    },
    allowedDates(a) {
      return this.availableDates.includes(String(a));
    },
    // Function to update pickers date so that sunday and saturday is disabled
    pickerUpdate: function (val) {
      let availableDates = helpers.fetchAvalibleDates(val);
      this.availableDates = availableDates;
      this.allowedDates();
      return;
    },
  },
};
</script>
<style>
.v-date-picker-header {
  display: none;
}
</style>