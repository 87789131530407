<template>
  <div class="Time Report">
    <TimeReport></TimeReport>
  </div>
</template>

<script>
// @ is an alias to /src
import TimeReport from '@/components/timeReport/TimeReport.vue'

export default {
  name: 'TimeReportView',
  components: {
    TimeReport
  }
}
</script>