<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
      />
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="timeReports"
      :search="search"
      :sort-by="['month']"
      :sort-desc="[true]"
      single-expand
      show-expand
      :expanded.sync="expanded"
      multi-sort
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <expand-info-vacation
            :item="{ item }"
            v-show="item.type === 'Vacation'"
          />
          <expand-info-sickness
            :item="{ item }"
            v-show="item.type === 'Sickness'"
          />
          <expand-info-parental
            :item="{ item }"
            v-show="item.type === 'Parental leave'"
          />
          <expand-info-child-care
            :item="{ item }"
            v-show="item.type === 'Care of child (VAB)'"
          />
          <expand-info-normal v-show="item.type === 'Normal'" />
        </td>
      </template>
    </v-data-table>
    <Fab />
  </v-card>
</template>
<script>
import Fab from "@/components/timeReport/Fab.vue";
import expandInfoVacation from "@/components/timeReport/expandInfoVacation.vue";
import expandInfoSickness from "@/components/timeReport/expandInfoSickness.vue";
import expandInfoParental from "@/components/timeReport/expandInfoParental.vue";
import expandInfoChildCare from "@/components/timeReport/expandInfoChildCare.vue";
import expandInfoNormal from "@/components/timeReport/expandInfoNormal.vue";

import { mapGetters } from "vuex";

export default {
  Name: "TimeReport",
  components: {
    Fab,
    expandInfoVacation,
    expandInfoSickness,
    expandInfoParental,
    expandInfoChildCare,
    expandInfoNormal,
  },
  data: () => ({
    search: "",
    expanded: [],
    errors: [],
    headers: [
      {
        text: "Month",
        value: "month",
        align: "start",
      },
      {
        text: "Type",
        value: "type",
      },
      {
        text: "Project",
        value: "project.data.name",
      },
      {
        text: "Hours",
        value: "hours",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "right",
      },
    ],
    editedItem: {
      name: "",
    },
    defaultItem: {
      project: "",
      month: "",
      hours: 0.0,
    },
  }),
  computed: {
    ...mapGetters({
      timeReports: "timeReports/userTimeReports",
      editedIndex: "editedIndex",
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    editItem(item) {
      this.$store.dispatch("SET_EDITED_INDEX", this.timeReports.indexOf(item));
      this.editedItem = Object.assign({}, item);
      this.$store.dispatch("SET_EDITED_TIME_REPORT", this.editedItem);

      switch (this.editedItem.type) {
        case "Sickness":
          this.$store.dispatch("SET_DIALOG_SICK", true);
          break;
        case "Vacation":
          this.$store.dispatch("SET_DIALOG_VACATION", true);
          break;
        case "Parental leave":
          this.$store.dispatch("SET_DIALOG_PARENTAL", true);
          break;
        case "Care of child (VAB)":
          this.$store.dispatch("SET_DIALOG_CHILD_CARE", true);
          break;
        default:
          this.$store.dispatch("SET_DIALOG_MONTH", true);
          break;
      }
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store
          .dispatch("timeReports/deleteUserTimeReport", item)
          .catch((e) => {
            this.errors.push(e);
          });
    },
  },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>