<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row id="core">
          <v-col cols="12" sm="12" lg="5">
            <v-menu
              v-model="monthPicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="monthPicker"
                  v-model="editedItem.month"
                  prepend-icon="mdi-calendar"
                  placeholder="time report month"
                  @change="clearAllowedDates()"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                v-model="editedItem.month"
                type="month"
                @input="monthPicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer cols="12" lg="2" />
          <v-col cols="12" lg="5" sm="12">
            <v-text-field
              id="sickHoursFistDay"
              v-model="editedItem.sickHoursFirstDay"
              single-line
              label="Sick hours first day"
              type="number"
              :rules="[rules.required]"
              @change="updateSickHours"
              required
              hint="Hours absent first day of sickness leave"
              persistent-hint
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="text-body-1">
                Select range of sickness days
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" lg="6">
                    <v-date-picker
                      v-model="sDates"
                      no-title
                      show-week
                      range
                      full-width
                      show-current
                      first-day-of-week="1"
                      :allowed-dates="allowedDates"
                      @update:picker-date="pickerUpdate($event)"
                      :picker-date="editedItem.month"
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" sm="12" lg="6">
                    <p class="text-h6">Information</p>
                    <v-divider></v-divider>
                    <p class="text-body">
                      A manager should be notified by email if sick leave is
                      longer then 2 weeks.
                    </p>
                    <p class="text-body">
                      In the case of full or part time long term sick leave,
                      economy needs to be notified.
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="cancel" text @click="close"> Close </v-btn>
      <v-btn color="primary" text @click="saveMonth"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "@/components/timeReport/helpers.js";

function calcSickHours(startDate, endDate, hoursFirstDay) {
  let hours = null;
  if (endDate === null) {
    hours = parseInt(hoursFirstDay, 10);
    return hours;
  }

  let start = new Date(startDate)

  // Get number of days between start and stop
  const oneDay = 24 * 60 * 60 * 1000; // milleseconds a day
  const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));

  // Find if the interval include weekends always remove the first two weekends
  // if the interval stretches over them.

  // if the number of sick days are more then 14 calender days anymore weekends
  // should be included.

  let endDay = start.getDay() + diffDays;
  let daysToBeRemoved = 0
  if (endDay > 5) {
    daysToBeRemoved = 2
  }

  if (endDay >= 13){
    daysToBeRemoved = daysToBeRemoved + 2
  }

  hours = (diffDays * 8) + hoursFirstDay - (daysToBeRemoved * 8);
  return hours;
}

export default {
  name: "MonthlySickCard",
  components: {},
  data: () => ({
    month: new Date().toISOString().substr(0, 7),
    _availableDates: [],
    monthPicker: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  computed: {
    ...mapGetters({
      editedItem: "editedTimeReport",
      editedIndex: "editedIndex",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "New Sickness Report"
        : "Edit Sickness Report";
    },
    sDates: {
      get() {
        return this.editedItem.sickDays;
      },
      set(value) {

        this.editedItem.sickDays = value.sort();
        let startDate = new Date(this.editedItem.sickDays[0]);
        let endDate = null;

        if (value.length == 2) {
          endDate = new Date(this.editedItem.sickDays[1]);
        }
        let hours = calcSickHours(
          startDate,
          endDate,
          parseInt(this.editedItem.sickHoursFirstDay, 10)
        );
        this.editedItem.hours = hours;
        return;
      },
    },
    availableDates: {
      get() {
        return helpers.fetchAvalibleDates(this.editedItem.month);
      },
      set(value) {
        this._availableDates = value;
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      //TODO This should be replace with a model, form a lib
      let tr = helpers.createTimeReport();
      // set month to current month
      tr.month = new Date().toISOString().substr(0, 7);
      this.$store.dispatch("SET_EDITED_TIME_REPORT", tr);
      this.$store.dispatch("SET_EDITED_INDEX", -1);
      this.$store.dispatch("SET_DIALOG_SICK", false);
    },
    saveMonth() {
      //TODO: Should do a check if the needed field are set, if not raise a alert
      this.editedItem.type = "Sickness";
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("timeReports/updateUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("timeReports/addUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.close();
    },
    updateSickHours() {
      // sickDays are empty set the hours to zero, sickDays has not been added
      if (this.editedItem.sickDays.length <= 0) {
        this.editedItem.hours = 0;
        return;
      }

      let startDate = new Date(this.editedItem.sickDays[0]);

      // check if enddate exsist
      let endDate = null;

      if (this.editedItem.sickDays.length > 1) {
        endDate = new Date(this.editedItem.sickDays[1]);
      }

      this.editedItem.hours = calcSickHours(
        startDate,
        endDate,
        parseInt(this.editedItem.sickHoursFirstDay, 10)
      );
    },
    allowedDates(a) {
      return this.availableDates.includes(String(a));
    },
    // Function to update pickers date so that sunday and saturday is disabled
    pickerUpdate: function (val) {
      let availableDates = helpers.fetchAvalibleDates(val);
      this.availableDates = availableDates;
      this.allowedDates();
      return;
    },
  },
};
</script>
<style>
.v-date-picker-header {
  display: none;
}
</style>