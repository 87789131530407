<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row id="core">
          <v-col cols="12" sm="12" lg="5">
            <v-menu
              v-model="monthPicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="monthPicker"
                  v-model="editedItem.month"
                  prepend-icon="mdi-calendar"
                  placeholder="time report month"
                  @change="clearAllowedDates()"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                v-model="editedItem.month"
                type="month"
                @input="monthPicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer cols="12" lg="2" />
        </v-row>
        <v-row>
          <v-col cols="12" lg="5" sm="5">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="datePicker"
                  v-model="date"
                  prepend-icon="mdi-calendar"
                  placeholder="time report month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[rules.required]"
                />
              </template>
              <v-date-picker
                v-model="date"
                @input="datePicker = false"
                show-current
                show-week
                no-title
                :allowed-dates="allowedDates"
                first-day-of-week="1"
                @update:picker-date="pickerUpdate($event)"
                :picker-date="editedItem.month"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" lg="5" sm="5">
            <v-text-field
              id="hours"
              v-model="editVabHours"
              single-line
              label="Hours"
              type="number"
              :rules="[rules.required]"
              hint="Hours of caring for child"
              persistent-hint
            />
          </v-col>
          <v-col cols="12" lg="2" sm="2">
            <v-btn color="primary" elevation="2" icon large @click="addVABItem">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table :headers="vabHeaders" :items="editedItem.vabItems">
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteVABItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="cancel" text @click="close"> Close </v-btn>
      <v-btn color="primary" text @click="saveMonth"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import helpers from "@/components/timeReport/helpers.js";

export default {
  name: "MonthlyCardChildCard",
  components: {},
  data: () => ({
    month: new Date().toISOString().substr(0, 7),
    date: new Date().toISOString().substr(0, 10),
    _availableDates: [],
    monthPicker: false,
    datePicker: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    vabHeaders: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "date",
      },
      { text: "Hours", value: "hours", sortable: false },
      { text: "Actions", value: "actions", sortable: false, align: "right" },
    ],
    editVabHours: 0,
  }),
  computed: {
    ...mapGetters({
      editedItem: "editedTimeReport",
      editedIndex: "editedIndex",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "New Child Care Report"
        : "Edit Child Care Report";
    },
    availableDates: {
      get() {
        return helpers.fetchAvalibleDates(this.editedItem.month);
      },
      set(value) {
        this._availableDates = value;
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      //TODO This should be replace with a model, form a lib
      let tr = helpers.createTimeReport();
      // set month to current month
      tr.month = new Date().toISOString().substr(0, 7);
      this.$store.dispatch("SET_EDITED_TIME_REPORT", tr);
      this.$store.dispatch("SET_EDITED_INDEX", -1);
      this.$store.dispatch("SET_DIALOG_CHILD_CARE", false);
    },
    saveMonth() {
      //TODO: Should do a check if the needed field are set, if not raise a alert
      this.editedItem.type = "Care of child (VAB)";
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("timeReports/updateUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("timeReports/addUserTimeReport", {
            item: this.editedItem,
            user: this.$store.state.users.user,
          })
          .catch((e) => {
            console.log(e);
          });
      }
      this.close();
    },
    allowedDates(a) {
      return this.availableDates.includes(String(a));
    },
    // Function to update pickers date so that sunday and saturday is disabled
    pickerUpdate: function (val) {
      let availableDates = helpers.fetchAvalibleDates(val);
      this.availableDates = availableDates;
      this.allowedDates();
      return;
    },
    addVABItem() {
      // Add vab item
      let item = {
        date: this.date,
        hours: this.editVabHours,
      };
      if (this.editedItem.month !== this.date.substr(0, 7)) {
        alert(`Date is not in selected month ${this.editedItem.month}`);
        return;
      }

      const index = this.editedItem.vabItems.findIndex(
        (x) => x.date === item.date
      );
      if (index !== -1) {
        alert(`Item already exist with the same date ${item.date}`);
        return;
      }
      this.editedItem.vabItems.push(item);
      //TODO sort vabItems after list ahve been modified

      // Update hours
      let hours = 0;
      this.editedItem.vabItems.forEach((i) => {
        hours = hours + parseInt(i.hours, 10);
      });
      this.editedItem.hours = hours;
    },
    deleteVABItem(item) {
      const index = this.editedItem.vabItems.findIndex(
        (x) => x.date === item.date
      );
      this.editedItem.vabItems.splice(index, 1);
      //TODO sort vabItems after list ahve been modified
    },
  },
};
</script>
<style>
.v-date-picker-header {
  display: none;
}
</style>