<template>
  <v-main>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else>mdi-calendar-plus</v-icon>
        </v-btn>
      </template>
      <v-btn fab small color="darker" @click="DialogParentalOpen">
        <v-icon color="white">mdi-pencil</v-icon>
        <div class="fab-text-custom darker white--text">
          Add Monthly parental leave report
        </div>
      </v-btn>
      <v-btn fab small color="darker" @click="DialogVacationOpen">
        <v-icon color="white">mdi-pencil</v-icon>
        <div class="fab-text-custom darker white--text">
          Add Monthly vacation report
        </div>
      </v-btn>
      <v-btn fab small color="darker" @click="DialogChildCareOpen">
        <v-icon color="white">mdi-pencil</v-icon>
        <div class="fab-text-custom darker white--text">
          Add Monthly child care report
        </div>
      </v-btn>
      <v-btn fab small color="darker" @click="DialogSickOpen">
        <v-icon color="white">mdi-pencil</v-icon>
        <div class="fab-text-custom darker white--text">
          Add Monthly sickness report
        </div>
      </v-btn>
      <v-btn fab small color="darker" @click="DialogMonthOpen">
        <v-icon color="white">mdi-pencil</v-icon>
        <div class="fab-text-custom darker white--text">
          Add Monthly time report
        </div>
      </v-btn>
    </v-speed-dial>
    <v-dialog v-model="dialogMonth" max-width="600" persistent scrollable>
      <MonthlyFormCard> </MonthlyFormCard>
    </v-dialog>
    <v-dialog v-model="dialogSick" max-width="600" persistent scrollable>
      <MonthlySickCard></MonthlySickCard>
    </v-dialog>
    <v-dialog v-model="dialogVacation" max-width="600" persistent scrollable>
      <MonthlyVacationCard></MonthlyVacationCard>
    </v-dialog>
    <v-dialog v-model="dialogParental" max-width="600" persistent scrollable>
      <MonthlyParentalCard></MonthlyParentalCard>
    </v-dialog>
    <v-dialog v-model="dialogChildCare" max-width="600" persistent scrollable>
      <monthly-child-care-card />
    </v-dialog>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import MonthlyFormCard from "@/components/timeReport/MonthlyFormCard.vue";
import MonthlySickCard from "@/components/timeReport/MonthlySickCard.vue";
import MonthlyVacationCard from "@/components/timeReport/MonthlyVacationCard.vue";
import MonthlyParentalCard from "@/components/timeReport/MonthlyParentalCard.vue";
import MonthlyChildCareCard from "./MonthlyChildCareCard.vue";

export default {
  name: "Fab",
  components: {
    MonthlyFormCard,
    MonthlySickCard,
    MonthlyVacationCard,
    MonthlyParentalCard,
    MonthlyChildCareCard,
  },
  data: () => ({
    fab: false,
  }),
  computed: {
    ...mapGetters({
      dialogMonth: "dialogMonth",
      dialogSick: "dialogSick",
      dialogVacation: "dialogVacation",
      dialogParental: "dialogParental",
      dialogChildCare: "dialogChildCare",
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    DialogMonthOpen() {
      this.$store.dispatch("SET_DIALOG_MONTH", true);
    },
    DialogSickOpen() {
      this.$store.dispatch("SET_DIALOG_SICK", true);
    },
    DialogVacationOpen() {
      this.$store.dispatch("SET_DIALOG_VACATION", true);
    },
    DialogParentalOpen() {
      this.$store.dispatch("SET_DIALOG_PARENTAL", true);
    },
    DialogChildCareOpen() {
      this.$store.dispatch("SET_DIALOG_CHILD_CARE", true);
    },
  },
};
</script>

<style scoped>
#fab .v-speed-dial {
  position: absolute;
}

#fab .v-btn--floating {
  position: relative;
}

.fab-text-custom {
  position: absolute;
  right: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
</style>