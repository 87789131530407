import dateFormat from "dateformat";

function calcCurrentMonthAvalibleDates() {
    var dt = new Date();
    var month = dt.getMonth();
    var year = dt.getFullYear();
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        let df = dateFormat(date, "yyyy-mm-dd");
        if (date.getDay() !== 0 && date.getDay() !== 6) {
            days.push(df);
        }
        date.setDate(date.getDate() + 1);
    }
    return days;
}

function fetchAvalibleDates(dateMonthString) {
    let month = new Date(dateMonthString).getMonth();
    let year = new Date(dateMonthString).getFullYear();
    let totalDay = new Date(year, month + 1, 0).getDate();

    let availableDates = [];

    for (let i = 1; i <= totalDay; i++) {
        let date = new Date(year, month, i);
        if (date.getDay() !== 0 && date.getDay() !== 6) {
            let df = dateFormat(date, "yyyy-mm-dd");
            availableDates.push(df);
        }
    }

    return availableDates
}

function createTimeReport(month = "", hours = 0,
    project = null, type = "Normal",
    vacationDates = [],
    sickDays = [], sickHoursFirstDay = 0,
    parentalDays = [], parentalLeaveExtent = 100,
    vabItems = []) {
    return {
        month,
        hours,
        project,
        type,
        vacationDates,
        sickDays,
        sickHoursFirstDay,
        parentalDays,
        parentalLeaveExtent,
        vabItems
    }
}

export default { calcCurrentMonthAvalibleDates, createTimeReport, fetchAvalibleDates }